<template>
  <v-container class="pt-10">
    <User />
  </v-container>
</template>

<script>
import User from "../sections/user";

export default {
  name: "MobileCart",

  components: {
    User
  }
};
</script>
