<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        required
      ></v-text-field>

      <v-text-field
        v-if="!reset"
        v-model="pwd"
        :rules="pwdRules"
        label="Password"
        type="password"
        required
      ></v-text-field>

      <v-text-field
        v-if="signUp == 2"
        v-model="username"
        :rules="usernameRules"
        label="Full Name"
      ></v-text-field>

      <v-text-field
        v-if="signUp == 2"
        v-model="phoneNumber"
        :rules="phoneNumberRules"
        label="Phone Number"
      ></v-text-field>
      <v-row>
        <v-btn
          v-if="reset"
          :loading="loader"
          :disabled="!valid"
          color="success"
          class="mb-2"
          @click="authReset"
          small
        >
          Reset
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="signUp == 1"
          @click="reset = !reset"
          v-text="reset ? 'Login your account' : 'Forgot password'"
          text
          small
        >
        </v-btn>
      </v-row>
      <v-row v-if="signUp == 1 && !reset">
        <v-btn
          :loading="loader"
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validate(true)"
        >
          Login
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="ma-1" @click="signUp = 2" text>
          <v-icon>mdi-account-plus</v-icon>Sign Up
        </v-btn>
      </v-row>
      <v-row v-if="signUp == 2 && !reset">
        <v-btn
          :loading="loader"
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validate(false)"
        >
          Sign Up
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="ma-1" @click="signUp = 1" text>
          <v-icon>mdi-login</v-icon>Login?
        </v-btn>
      </v-row>
      <v-snackbar :value="snackbar" :multi-line="true">
        {{ snackbarMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "Login",

  props: {
    next: Number
  },

  data: () => ({
    valid: true,
    signUp: 1,
    reset: false,
    loading: false,
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    pwd: "",
    pwdRules: [v => !!v || "Password is required"],
    username: "",
    usernameRules: [v => !!v || "Username is required"],
    phoneNumber: "",
    phoneNumberRules: [v => !!v || "Phone Number is required"]
  }),

  methods: {
    validate(login) {
      if (this.$refs.form.validate()) {
        var emails = this.email.trim();
        console.log(emails);
        var data = {
          pwd: this.pwd,
          email: emails,
          phoneNumber: this.phoneNumber,
          username: this.username
        };
        if (login) {
          this.$store.dispatch("auth/signIn", data);
        } else {
          this.$store.dispatch("auth/signUp", data);
        }
      }
    },
    authReset() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("auth/reset", this.email);
      }
    },
    notifications(msg) {
      this.snackbarText = msg;
      this.snackbar = !this.snackbar;
    },
    resetForm() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  },

  computed: {
    loader() {
      return this.$store.getters.load;
    },
    snackbarMsg() {
      return this.$store.getters.msg;
    },
    snackbar() {
      return this.$store.getters.showMsg;
    }
  }
};
</script>
