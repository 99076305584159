<template>
  <v-card>
    <v-system-bar color="primary" dark app> </v-system-bar>
    <v-app-bar dark color="primary" app>
      <v-toolbar-title>User Account</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-expansion-panels class="mt-4">
        <v-expansion-panel>
          <v-expansion-panel-header>
            LOGIN
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Login :firebase="fb" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-main>
  </v-card>
</template>

<script>
import Login from "../components/login";
import { fb } from "../firebase";

export default {
  name: "User",

  data: () => ({
    fb: fb
  }),

  components: {
    Login
  }
};
</script>
